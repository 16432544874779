.sg-job-detail__item-header {
  opacity: .8;
  margin-bottom: 10px;
}

  .sg-job-detail__item-header > h5 {
    margin-bottom: 0;
  }


.sg-job-detail__item-list {
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
}

  .sg-job-detail__item {
    padding: 0;
    margin: 0;

    background: #fff;
    border: 1px solid #ccc;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2px;
    overflow: hidden;
    border-radius: 2px;
    cursor: pointer;
    color: #333;
    font-size: 18px;
    vertical-align: middle;
    padding-right: 8px;
  }

  .sg-job-detail__item--selected {
    background: #3680BF;
    border: 1px solid #3680BF;
    color: #fff;
  }
