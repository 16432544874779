/* Remove extra padding on desktop */
@media (min-width: 768px) {
  .cl-mcont {
    padding: 20px;
  }

  .cl-mcont > .container-fluid {
    padding: 0;
  }

  .cl-mcont > .container-fluid > .row {
    margin-top: 0;
  }
}

/* Remove extra padding on mobile */
@media (max-width: 767px) {
  .cl-mcont {
    padding: 0;
  }

  .cl-mcont > .container-fluid > .row {
    margin-top: -1px;
  }

  .cl-mcont > .container-fluid > .row > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  .page-head {
    padding: 15px;
  }
}

